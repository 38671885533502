.publishScheduleModal_tableContainer {
  height: 25em;
  overflow-y: scroll;
}

.publishScheduleModal_table {
  width: 100%;
  text-align: center;

  thead {
    font-size: 1em;
    font-weight: 400;
    color: white;
    position: sticky;
    top: 0;

    th {
      background: var(--primary);
      padding: 0.75em 0;
      font-weight: 400;
    }
  }

  tbody {
    tr {
      border-bottom: 2px solid #e8e8e8;

      td {
        padding: 1em 0;
      }
    }
  }
}