@import 'src/variables';



/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: $grey;
  text-align: center;
  padding: 0.75em 1em;
  text-decoration: none;
  font-size: 1.2em;
}

/* Add a color to the active/current link */
.topnav a.active {
  color: $primary;
}

.topnav .topNav_logo {
  width: 4em;
  float: left;
}

#top-navigation-bar {
    .nav-link {
        display: flex;
        margin-right: 10px;

        svg {
            margin-right: 0.5rem; 
        }

        &.active {
            color: var(--primary);
        }

    }
}

.navBar_icon {
  font-size: 24px;
}

.navBar_dropdownIcon {
  @extend .navBar_icon;
  color: $grey;
  margin-right: 0.5rem;
}

.navBar_dropdownContainer {
  display: flex;
  color: $grey;
  text-align: start;
  vertical-align: middle;
  padding: 0.5rem 0;
  text-decoration: none;
  font-size: 1rem;
  margin-right: 10px;

  a {
    padding: 0 !important;
  }

  .dropdown-item {
    padding: 0.25rem 1.5rem !important;
  }
}