.caddieSlot_container {
  width: 23%;
  height: 100%;
}

.caddie-slot-min-width {
  min-width: 120px !important;
  margin-right: 10px;
  margin-bottom: 10px;
}

.caddieSlot {
  width: 100%;
  min-height: 10em;
  border-radius: 8px;
}

.emptyCaddieSlot {
  @extend .caddieSlot;
  background-color: white;
  border: 1px solid #707070;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.emptyCaddieSlot_hovered {
  border: 1px solid var(--primary);
  box-shadow: 0 0 11px rgba(33,33,33,.2);

  .emptyCaddieSlot_svg {
    fill: var(--handicaddie-light-green);
  }
}

.caddieSlot_notEmpty {
  @extend .caddieSlot;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .caddieSlot_optionsContainer {
    padding-top: 0.5em;
    padding-right: 0.5em;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.caddieSlot_OPEN {
  @extend .caddieSlot_notEmpty;

  background-color: #EEE;
  border: 1px solid #707070;
  height: 100%;
}

.caddieSlot_NO_SMARTPHONE {
  @extend .caddieSlot_notEmpty;

  background-color: #B2F6FF;
}

.caddieSlot_PUBLIC {
  @extend .caddieSlot_notEmpty;

  background-color: #B0B0B0;
  border: 1px solid #707070;
  height: 100%;
}

.caddieSlot_ACCEPTED {
  @extend .caddieSlot_notEmpty;

  background-color: #D7FFE5;
}

.caddieSlot_REJECTED {
  @extend .caddieSlot_notEmpty;

  background-color: #FFD8D8;
}

.caddieSlot_DRAFT {
  @extend .caddieSlot_PENDING;
}

.caddieSlot_PENDING {
  @extend .caddieSlot_notEmpty;

  background-color: #FFF1E3;
}

.slotString {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.draftIndicator {
  width: 100%;
  display: flex;
  justify-content: center;

  div {
    width: 75%;
    height: fit-content;
    padding: 0.5em;
    border-radius: 25px;
    background-color: #dae8fa;
    color: #0D6DFF;

    display: flex;
    justify-content: center;

    svg {
      margin-right: 0.25em;
    }
  }
}

.rejectReasonIndicator  {
  @extend .draftIndicator;

  div {
    background-color: #fdf3f3;
    color: #020202;
    text-align: center;
  }
}

.caddieSlot_infoIcon {
  margin-left: 0.5em;
  margin-top: 2px;
  color: var(--highlight-darker-handicaddie-green);
}

#slot-type-dropdown {
  width: 100%;
  padding: 6px;
  margin-top: 10px;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #707070 !important;
}

.slot-type-menu-item {
  padding: 5px 10px;
}

.slot-type-title {
  display: inline-block;
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}
