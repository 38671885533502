.announcementBanner {
  width: 100%;

  background-color: #4caf50;

  padding: 1em;

  margin-top: 75px;
}

.announcementBanner_text {
  color: white;
}

.announcementBanner_avatar {
  width: 5em;
  height: 5em;
  margin-right: 1em;
  object-fit: cover;
}

.announcementBanner_welcome {
  @extend .announcementBanner_text;

  text-transform: uppercase;
}

.announcementBanner_username {
  @extend .announcementBanner_text;

  text-transform: capitalize;
}

.announcementBanner_userRow {
  margin-bottom: 0.5em;
}

.announcementBanner_dismissBtn {
  font-size: 0.8em;
  color: #000;

  background-color: #e7e7e7;

  border-radius: 15em;
  padding: 4px;
  line-height: 1em;
  cursor: pointer;
}

.announcementBanner_dismissBtn_container {
  display: flex;
  justify-content: flex-end;

  height: 1.2em;
  border-radius: 15em;

}