.availabilitySelection_intro {
    font-size: 1em;
    margin-bottom: 1em;
}

.availabilitySelection_calendarContainer {
    margin-bottom: 2em;
    display: flex;
    justify-content: center;
}

.availabilitySelection_updateBtn {
    margin-top: 1em;
    margin-bottom: 2em;
}

.available-date {
    background-color: lightgray;
    border-radius: 20px;
    padding: 0.5rem 0.8rem;
    margin: 0.5rem;
    display: inline-flex;
    font-size: 0.8rem;
    font-weight: bold;
    min-width: 9rem;

    &.booked-date {
        background-color: var(--primary);
    }

    > .date {
        line-height: 1.6rem;
    }
}

.schedule-container {
    width: 100%;
    margin: auto;

    @media only screen and (min-width: 900px) {
        width: 75%;
    }

}

.booking-legend {
    display: flex;
    margin-bottom: 2rem;
}
.legend-item {
    border-radius: 20px;
    background-color: lightgray;
    width: auto;
    padding: 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0 0.5rem;

    &.booked {
        background-color: var(--primary);
    }
}

.clear-button {
    background-color: #66a966a1;
    border-radius: 100%;
    padding: 5px;
    font-size: 10px;
    margin-left: 10px;
    height: 24px;
    width: 24px;
    display: flex;
    font-weight: bold;
    cursor: pointer;

    > span {
        margin: auto;
        color: lightgray;
    }
}
