@import "src/components/announcement/AnnouncementBanner";


.welcomeBanner_avatar {
  width: 3em;
  height: 3em;
}


.welcomeBanner_ceoRow {
  margin-bottom: 0.5em;

  padding-left: 1em;
}

.welcomeBanner_ceoRow_name {
  @extend .announcementBanner_text;

  font-weight: 600;

  margin: 0;
  padding: 0;
}

.welcomeBanner_ceoRow_position {
  @extend .announcementBanner_text;

  margin: 0;
  padding: 0;
}