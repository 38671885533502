@import 'src/variables';

.coursesTable_editBtn {
  color: var(--darker-handicaddie-green);
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 0.5em;
}

.coursesTable_deleteBtn {
  color: $danger;
  cursor: pointer;
}