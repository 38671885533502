@import 'src/variables';

.userProfileHeader_container {
  background-color: $primary;

  padding: 2em;
  margin-bottom: 3em;

  color: white;
}

.userProfileHeader_avatar {
  display: flex;
  width: 15em;
  height: 15em;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 15px;
}

.userProfileHeader_editBtn {
  text-transform: uppercase;
  width: 100%;
}