.bookingsTable_header {
  background-color: white;
  color: #777;
  text-transform: uppercase;
}

.bookingsTable_bodyContainer {
  background-color: #fff;
}

.bookingsTable_dateRangeContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: var(--primary);;
  padding: 1em 1em;
  color: white;
}

.bookingsTable_dateContainer {
  display: flex;
  background-color: white;
  border-radius: 5px;
  padding: 0.5em;
  text-align: center;
  //line-height: 2.5em;
}

.bookingsTable_dateContainer_badge {
  font-size: 0.75em;
  line-height: 1.75em;
  background-color: green;
  border-radius: 5px;
  margin-right: 1em;
  padding: 0 1em;
}

.bookingsTable_dateContainer_dates {
  color: black;
  padding-right: 1em;
}

.bookingsTable_calendarContainer {
  flex-direction: row;
}

.bookingsTable_calendarLabelsContainer {
  width: 25%;
  background-color: white;
  display: table-cell;
  height: 1px;
}

.bookingsTable_calendarLabelsList {
  list-style-type: none;
  margin: 0;
  padding: 1em;
  height: 100%;
  width: 100%;
  overflow: scroll;

  li {
    width: 100%;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }

  li:hover {
    background-color: #eee;
  }
}

.bookingsTable_calendar {
  display: table-cell;
}
