@import 'src/variables';

.caddieTable_header {
  margin-bottom: 0.5em;
}

.caddieTable_table {
  margin-bottom: 0;
}

.caddieTable_headingContainer {
  background-color: $primary;
  color: white;
  height: 2em;
  font-size: 1em;
}

.caddieTable_heading {
  font-size: 1.2em;
  line-height: 2em;
  text-transform: uppercase;
  color: white;
  padding: 0;
  margin: 0;
}

.caddieTable_bodyContainer {
  background-color: #fff;
  font-size: 1.1em;

  tr {
    border-bottom: 1px solid #dee2e6;
  }
}

.caddieTable_colOne {
  width: 75%;
}

.caddieTable_season {
  line-height: 4em;
}

.caddieTable_avatar {
  width: 4em;
  height: 4em;
  margin-right: 1em;
}

.caddieTable_footer {
  padding-bottom: 10em;
}

.caddieTable_footer_pageBtnContainer {
  padding-top: 2em;
}

.caddieTable_footer_pageBtn {
  margin-right: 0.5em;
}

