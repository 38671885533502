.upcoming-booking-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: #ffffff;
  background-color: #20bc54;
}

.booking-table {
  overflow-x: auto;
  background-color: #ffffff;
  min-height: 25em;

  th {
    padding: 10px;
    background: #ffffff;
    border-bottom: 2px solid #dddee1;
  }
  td {
    padding: 10px;
  }
  tbody tr:nth-child(odd) {
    background-color: #f0ecec;
  }
}

.bookings-table-content {
  width: 100%;
  min-width: 750px;
}

.render-caddie-name {
    padding: 0px 5px;
    border-radius: 5px;
    margin: 2px;
    color: #ffffff;
    font-weight: 700;
}

.pending-booking {
  background-color: #ffc107;
}

.accepted-booking {
  background-color: #28a745;
}

.rejected-booking {
  background-color: #dc3545;
}

.no-smartphone {
  background-color: #3578dc;
}

.draft-booking {
  background-color: #3578dc;
}