@import 'src/variables';

.planCard_heading {
  color: #757575;
  text-transform: uppercase;
}

.planCard_heading_year {
  @extend .planCard_heading;

  font-size: 2.3em;
  margin: 0;
}

.planCard_heading_season {
  @extend .planCard_heading;

  font-size: 1.4em;
}

.planCard_heading_validHeading {
  @extend .planCard_heading;

  margin-top: 1em;
  font-size: 1em;
}

.planCard_price {
  text-align: center;
  font-size: 5em;
  font-weight: 300;
}

.planCard_payBtn {
  width: 50%;
}


.planCard_paidIcon {
  font-size: 2em !important;
  color: $primary;
  float: right;
}