// @import 'src/index';

.drag-drop-zone {
  padding: 5em;
  margin-bottom: 2em;
  text-align: center;
  background: #e7e7e7;
  border-radius: 0.5rem;
  border: 2px dashed #b6b6b6;
}
