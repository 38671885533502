@import 'src/variables';

$active_indicator: $primary;
$pending_indicator: #c3b31b;
$caddieMaster_indicator: #363685;
$admin_indicator: purple;

.membershipTable {
  margin-bottom: 3em;
}

.membershipTable_headingContainer {
  background-color: $primary;
  color: white;
  height: 2em;
}

.membershipTable_bodyContainer {
  background-color: #fff;

  tr {
    border-bottom: 1px solid #dee2e6;
  }
}

.membershipTable_heading {
  font-size: 1.2em;
  line-height: 2em;
  text-transform: uppercase;
  color: white;
  padding: 0;
  margin: 0;
}

.membershipTable_actions_addBtn {
  min-width: 6em;
}

.membershipTable_actions_addBtn_icon {
  font-size: 1.1em;
  margin-right: 10px;
  color: white;
  margin-bottom: 0.2em;
}

.membershipTable_colOne {
  width: 60%;
}

.membershipTable_colTwo {
  width: 25%;
}

.membershipTable_content_colOne {
  width: 65%;
}

.membershipTable_status {
  display: flex;
  font-size: 1em;
  font-weight: 500;

  div {
    width: 7px;
    height: 7px;
    background-color: #0f1315;
    border-radius: 50%;

    margin-right: 0.4em;

    display: flex;
    align-self: center;
  }

}

.membershipTable_status_caddie {
  @extend .membershipTable_status;

  color: $active_indicator;

  div {
    background-color: $active_indicator;
  }
}

.membershipTable_status_pending {
  @extend .membershipTable_status;

  color: $pending_indicator;

  div {
    background-color: $pending_indicator;
  }
}

.membershipTable_status_caddie_master {
  @extend .membershipTable_status;

  color: $caddieMaster_indicator;

  div {
    background-color: $caddieMaster_indicator;
  }
}

.membershipTable_status_admin {
  @extend .membershipTable_status;

  color: $admin_indicator;

  div {
    background-color: $admin_indicator;
  }
}

.membershipTable_emptyStateRow {
  background-color: #e5e5e5;
  color: #6a6a6a;
}

