@import "src/variables"; // Import your custom variables first
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #f5f5f5;

}
html,body{
  height: 100%;
}
#root{
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contentContainer {
  margin-top: 1em;
  max-width: 100%;
}

.table th, .table td {
  vertical-align: middle;
}

.caddieCard {
  min-width: 45% !important;
  flex-grow: 0 !important;
  margin-top: 1em;
  cursor: pointer;
}

.btn-link:focus {
  box-shadow: none;
}

.caddieCard img {
  width: 5em;
}

.caddieCard h1 {
  font-size: 2em;
  font-weight: 400;
}

.caddieSelector_caddieCard {
  margin-top: 1em;
  min-width: 45% !important;
  flex-grow: 0 !important;
}

.caddieSelector_caddieCard img {
  width: 3em;
}

.heading {
  margin-top: 1em;
}

.scheduler_bookingCard {
  margin-bottom: 0.75em;
}

.card_menu_img {
  font-size: 0.45em;
  height: 3em;
  color: grey;
  float: right;
}

.card_menu_edit {
  margin-right: 2em;
}

.card_drop_shadow {
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.dropdown-toggle::after {
  content: none !important;
}

.pageHeading {
  font-size: 2em;
  text-transform: uppercase;
  min-height: 3em;
  color: #757575;
  margin-top: 2em;
}

.pageSubHeading {
  color: black;
  font-size: 1.4em;
  text-transform: uppercase;
  min-height: 2em;
}

.pageSubSubHeading {
  color: black;
  font-size: 1.2em;
  text-transform: uppercase;
  min-height: 2em;
}

.page_section {
  border-bottom: 1px solid #b4b4b4;
  padding-bottom: 2em;
  margin-bottom: 2em;
}
// dropdown
.dropdown > .dropdown-toggle{
  background-color: #fff;
  border-color: #dcdcdc;
  color: #000;
}

.dropdown  > .dropdown-toggle:focus {
  background-color: #fff !important;
  border-color: #dcdcdc !important;
  color: #000 !important;
  box-shadow: none !important;
}

.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--darker-handicaddie-green) !important;
}
.show > .btn-primary.dropdown-toggle{
  background-color: #fff !important;
  border-color: #dcdcdc !important;
  color: #000 !important;
  box-shadow: none !important;
}

.border-b-dropdown > button {
  border: 0;
  border-bottom: 1px solid #000;
  border-radius: 0;
  min-width: 200px;
  background-color: transparent;
  color: #000 !important;
}
.border-b-dropdown > button:hover {
  background-color: transparent !important;
  color: #000 !important;
}
.border-b-dropdown > button:active {
  background-color: transparent !important;
  color: #000 !important;
}

.border-b-dropdown > button:focus {
  box-shadow: none !important;
}

.border-b-dropdown > .dropdown-menu {
  min-width: 200px;
}

.border-b-dropdown > .dropdown-toggle {
  box-shadow: none !important;
  background-color: transparent !important;
  border-color: var(--darker-handicaddie-green) !important;
}

.border-b-dropdown > .dropdown-menu .dropdown-item:active {
  background-color: var(--darker-handicaddie-green) !important;
}

.course-li-element {
  display: block;
  cursor: pointer;
  border-radius: 40px;
  margin: 12px 0;
}
.course-li-element:first-of-type {
  margin-top: 8px;
}
.course-li-element:last-of-type {
  margin-bottom: 0;
}

.course-li-element > span {
  width: fit-content;
  padding: 6px 20px;
  cursor: pointer;
  color: #fff;
  border-radius: 40px;
}
.course-li-element:hover > span {
  background-color: inherit;
  color: #000;
}


.color-code-preview{
  width: 18px;
  height: 18px;
}
.course-li-element-positioned {
  width: fit-content;
  padding: 4px 20px;
  border-radius: 40px;
  background-color: #757575;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #fff;
}

.width-100 {
  width: 100% !important;
}

.m-r-10 {
  margin-right: 10px;
}

.deleteBtn {
  color: $danger;
  cursor: pointer;
}
