.tasks-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #4a9454;
  background-color: #ccffde;
  padding: 15px 10px 15px 10px;
}

.expand-task-icon {
  padding: 2px;
  margin-left: 10px;
  border-radius: 5px;
  background-color: #136724;
  cursor: pointer;
  svg {
    vertical-align: bottom;
  }
}

.tasks-table {
  overflow: auto;
  max-height: 262px;
  background-color: #ffffff;

  table {
    border-collapse: separate;
    border-spacing: 0;
  }
  th {
    position: sticky;
    top: 0;
    padding: 10px 20px;
    background: #ffffff;
    border-bottom: 2px solid #dddee1;
  }
  td {
    padding: 2px 20px;
  }
}

.tasks-table-content {
  min-width: 650px;
}

#tasks-status-dropdown {
  background-color: #ffffff;
  color: #000000;
  border: none;
  padding: 0;
  box-shadow: none;
  font-weight: 700;
}

.tasks-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #136724;
  background-color: #e5f6ef;
  padding: 15px 10px 15px 10px;
}