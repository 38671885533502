@import 'src/variables';


.profileInfoTable_headingContainer {
  background-color: $primary;
  color: white;
  height: 2em;

  h3 {
    font-size: 1.3em;
    margin: 0;
    padding: 0;
  }
}

.profileInfoTable_bodyContainer {
  background-color: white;
  word-break: break-all;

}
