.autoPublishBanner {
  width: 100%;
  background-color: #4caf50;
  margin-top: 75px;
  padding: 0.15em 0;
  position: fixed;
  top: 0;
  z-index: 5000;
}

.autoPublishBanner_content {
  display: flex;
  justify-content: center;
}

.autoPublishBanner_text {
  color: white;
  text-align: center;
  margin: 0;
  padding: 0.25em 0.5em 0.25em 0;
}

.autoPublishBanner_hide {
  color: #dcdcdc;
  cursor: pointer;
  margin-top: 0.15em;
}