// @import "src/index";

.profileForm_card {
  width: 100%;
  margin-bottom: 1em;
}

.profileForm_sectionHeading {
  background-color: var(--primary);
  height: 2em;
  width: 100%;
}

.profileForm_profileImage {
  max-width: 300px;

  width: 8.5em;
  height: 8.5em;

  margin: 0 auto;
}

.profileForm_profileImage_input {
  display: none;
}

.profileForm_profileImage_label {
  margin: 1em auto;
  text-decoration: underline;
  color: var(--primary);
}

.profileForm_inputLabel {
  font-weight: bold;
  text-transform: uppercase;
  color: var(--text-dark);
}

.profileForm_actionPanel {
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: var(--white);
}

.profileForm_actionPanel_content {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.profileForm_actionPanel_btn {

}

.profileForm_actionPanel_paddedBtn {
  @extend .profileForm_actionPanel_btn;

  margin-right: 0.5em;
}

.profileForm_bio {
  min-height: 15em;
}