// @import "src/index";

.ladiesEuropeanTourPlans_sectionHeading {
  overflow: hidden;
  text-align: center;
  margin-top: 1em;
}

.ladiesEuropeanTourPlans_sectionHeading > span {
  color: #757575;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}

.ladiesEuropeanTourPlans_sectionHeading > span:before, .ladiesEuropeanTourPlans_sectionHeading > span:after{
  content: '';
  position: absolute;
  top: 50%;
  border-bottom: 2px solid;
  width: 591px; /* half of limiter*/
  margin: 0 20px;
}

.ladiesEuropeanTourPlans_sectionHeading > span:before{
  right: 100%;
}

.ladiesEuropeanTourPlans_sectionHeading > span:after{
  left: 100%;
}

