.pendingDocument {
  margin-bottom: 0.5em;
  width: 100%;
  background-color: white;
  padding: 0.75em;
  border-radius: 10px;

  //display: flex;
  justify-content: space-between;
}

.pendingDocument_fileName {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  padding: 0;
  line-height: 2em;
}