.caddie-programme-title {
  margin: 1em 0;
  padding: 1em;
  background-color: #ffffff;
  font-weight: 700;
}

.statistics-row-margin {
  margin-left: 0;
  margin-right: 0;
}

.statistics-column-padding {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 767px) {
  .statistics-column-left-padding {
    padding-left: 0;
  }
}

@media (min-width: 767px) {
  .statistics-column-right-padding {
    padding-right: 0;
  }
}

.statistics-container {
  height: 100%;
  padding-bottom: 1em;
}

.statistics {
  height: 100%;
  padding: 1em;
  background-color: #ffffff;
  text-align: center;
}

.statistics-heading-container {
  display: flex;
  width: 100%;

  justify-content: center;
}

.statistics-heading {
  font-size: 1em;
  text-transform: uppercase;
  color: #707070;
  flex: 0 1 auto;
  position: absolute;
}

.statistics-info-icon {
  font-size: 1em;
  color: var(--darker-handicaddie-green)
}

.statistics-heading-icon {
  flex: 0 1 auto;
  margin-left: auto;
}

.statistics-value {
  font-size: 3em;
  font-weight: bold;
}

.most-caddie-rounds-container {
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.most-caddie-rounds-table {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #ffffff;
}

.most-caddie-rounds-title {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #9c9c9c;
  border-bottom: 2px solid #dddee1;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #ffffff;
}

.caddie-details {
  padding: 1em;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}

.crown-icon {
  width: 15px;
  position: absolute;
  top: -12px;
  left: -21px;
  transform: rotate(47deg);
}

.first-caddie-position {
  filter: invert(89%) sepia(74%) saturate(3946%) hue-rotate(327deg) brightness(98%) contrast(104%);
}

.second-caddie-position {
  filter: invert(93%) sepia(0%) saturate(202%) hue-rotate(154deg) brightness(91%) contrast(94%);
}

.third-caddie-position {
  filter: invert(35%) sepia(81%) saturate(3487%) hue-rotate(29deg) brightness(94%) contrast(102%);
}

@media (max-width: 767px) {
  .most-caddie-rounds-container {
    padding-bottom: 1em;
  }

  .most-caddie-rounds-table {
    position: static;
  }
}