@import 'src/variables';


.caddiesTable {
  margin-bottom: 3em;
}

.caddiesTable_headingContainer {
  background-color: $primary;
  color: white;
  height: 2em;
}

.caddiesTable_bodyContainer {
  background-color: #fff;

  tr {
    border-bottom: 1px solid #dee2e6;
  }
}

.caddiesTable_col50Width {
  width: 50%;
}

.caddiesTable_viewProfileBtn {
  color: var(--darker-handicaddie-green);
  text-decoration: underline;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 0.5em;
}

.caddiesTable_deleteBtn {
  color: $danger;
  cursor: pointer;
}