.datesBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.flex {
  display: flex;
}

.todayContainerInput {
  width: 100%;
  height: fit-content;
  background-color: var(--darker-handicaddie-green);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: flex-end;
}

.todayContainerInput input {
  padding: 0.2em 0.5em;
  background-color: var(--darker-handicaddie-green);

  border: none;
  color: white;

  user-select: none;
}

.todayContainerInput svg {
  color: white;
  margin: 0.25em;
}

.todayContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  background-color: var(--darker-handicaddie-green);
  padding: 0.5em 1em;
  align-self: flex-end;
  cursor: pointer;
}

.todayContainer p {
  margin: 0;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  line-height: 2em;
}

.todayContainer svg {
  color: white;
  margin-right: 0.25em;
}

.todayContainer:hover {
  background-color: var(--highlight-darker-handicaddie-green);
}

.monthHeading {
  overflow: hidden;
  text-align: center;
  margin-right: 1em;
  margin-left: 1em;
  color: #888;
}

.monthHeading:before,
.monthHeading:after {
  background-color: #888;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.monthHeading:before {
  right: 5em;
  margin-left: -50%;
}

.monthHeading:after {
  left: 5em;
  margin-right: -50%;
}

.datesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.datesContainer svg {
  display: flex;
  color: white;
  align-self: center;
  text-align: center;
  border-radius: 100%;
  background-color: var(--darker-handicaddie-green);
  z-index: 1;
  cursor: pointer;
}

.datesContainer>div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
  background-color: white;
  outline: 1px solid;
}

.datesContainer>div>div {
  margin-left: 1px;
  width: 10%;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  cursor: pointer;
}

.datesContainer>div>div h5 {
  margin-top: 0;
  text-align: center;
  opacity: 0.5;
  font-size: 1em;
}

.datesContainer>div>div p {
  margin-bottom: 0;
  text-align: center;
  opacity: 0.5;
  font-size: 0.75em;
}

.datesContainer_highlighted {
  color: #62b967;
  opacity: 1 !important;
}

.scheduler_actionsBar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
}

.scheduler_actionBtn {
  height: 2.5em;
  width: 2.5em;
  background-color: var(--darker-handicaddie-green);
  text-align: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 0.25em;
}

.scheduler_publishBtn {
  height: 2.5em;
  padding: 0 1em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-weight: bold;
  color: white;
}

.scheduler_actionBtn:hover {
  background-color: var(--highlight-darker-handicaddie-green);
}

.scheduler_actionBtn:hover .mobileScheduler_dateInput {
  background-color: var(--highlight-darker-handicaddie-green);
}

.mobileScheduler_dateInput {
  background-color: var(--darker-handicaddie-green);
  color: white;
  border: none;
}

.mobileScheduler_dateInput:hover {
  background-color: var(--highlight-darker-handicaddie-green);
}

.mobileScheduler_dateInput::-webkit-calendar-picker-indicator {
  visibility: hidden;
}

.scheduler_bookingTypeButton_active {
  background-color: var(--darker-handicaddie-green);
  color: white;
  border: none;
}

.scheduler_bookingTypeButton_inactive {
  background-color: #eee;
  color: #555;
  border: 1px #555 solid;
}

.scheduler_bookingTypeButton_disabled {
  background-color: #eee;
  color: #999;
  border: 1px #eee solid;
  cursor: not-allowed !important;
}

.btn-bg-light-primary {
  background-color: #ccffde;
  border:1px solid var(--darker-handicaddie-green);
}

.btn-bg-light-primary:hover {
  background-color: #c0f0d0;
}

.table-btn-add-booking {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-radius: 0.5rem;
}

.table-btn-add-booking .add-sign {
  color: var(--darker-handicaddie-green);
  margin-right: 6px;
  flex-shrink: 0;
}
