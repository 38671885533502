.slot-types-title-text {
    font-size: 1.5em;
    font-weight: bold;
    color: #000000;
}

.caddie-slot-name-text {
    padding: 0.5em;
    color: #ffffff;
    background-color: #20bc54;
}

.add-slot-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    margin-bottom: 0.75em;
    border-radius: 0.5em;
    background-color: #ffffff;
}

.add-icon-button {
    width: 15em;
    margin-right: 1em;
    border: 1px solid #707070 !important;
    background-color: #ffffff !important;
}

.slot-type-name-input {
    margin-right: 1em;
    border: 1px solid #707070;
}

.slot-type-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    margin-bottom: 1em;
    background-color: #ffffff;
}

.selected-emoji {
    text-align: center;
    margin-bottom: 20px;
}
