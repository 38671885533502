@import 'src/variables';

.tourMembershipFeesTable_header {
  margin-bottom: 0.5em;
}

.tourMembershipFeesTable_table {
  margin-top: 1em;
  margin-bottom: 0;
}

.tourMembershipFeesTable_headingContainer {
  background-color: $primary;
  color: white;
  height: 2em;
  font-size: 1em;
}

.tourMembershipFeesTable_heading {
  font-size: 1.2em;
  line-height: 2em;
  text-transform: uppercase;
  color: white;
  padding: 0;
  margin: 0;
}

.tourMembershipFeesTable_bodyContainer {
  background-color: #fff;
  font-size: 1.1em;

  tr {
    border-bottom: 1px solid #dee2e6;
  }
}

.tourMembershipFeesTable_colOne {
  width: 50%;
}