@import "src/variables";

.choiceContent_container {
  padding-left: 2em;
  padding-right: 2em;
  justify-content: center;
}

.choiceContent_choice_container {
  border: $grey 1px solid;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
  padding: 1em;
}