
.caddie_panel_sorting_dropdown > button {
  border: 0;
  border-bottom: none;
  border-radius: 0;
  background-color: transparent;
  color: grey !important;

  padding: 12px 0;
}

.caddie_panel_sorting_dropdown > button:hover {
  background-color: transparent !important;
  color: #000 !important;
}

.caddie_panel_sorting_dropdown > button:active {
  background-color: transparent !important;
  color: #000 !important;
}

.caddie_panel_sorting_dropdown > button:focus {
  box-shadow: none !important;
}

.caddie_panel_sorting_dropdown > .dropdown-menu {
  min-width: 200px;
}

.caddie_panel_sorting_dropdown > .dropdown-toggle {
  box-shadow: none !important;
  background-color: transparent !important;
  border-color: var(--darker-handicaddie-green) !important;
}

.caddie_panel_sorting_dropdown > .dropdown-menu .dropdown-item:active {
  background-color: var(--darker-handicaddie-green) !important;
}