// colours
$primary: #4caf50;

:root {

  --handicaddie-light-green: #ccffde;
  --main-text-color: #4caf50;
  --secondary-text-color: #666;
  --text-dark: #000000de;

  --primary: #4caf50;

  --caddie-booking-colour: #ff6161;
  --availability-colour: #4caf50;
  --club-booking-colour: #6196ff;

  --darker-handicaddie-green: #136724;
  --highlight-darker-handicaddie-green: #1b8d32;
}

$grey: #b6b6b6;
$background: #f5f5f5;
$dark-background: #0f1315;
$danger:#dc3545;
$body-bg: $background;