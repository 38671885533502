.settings {
    display: flex;
}

.navigation-sidebar {
    width: 300px;
    padding-top: 1.5em;
    min-height: calc(100vh - 78px);
    background-color: #20bc54;
}

.settings-title-text {
    font-size: 1.5em;
    font-weight: bold;
    color: #ffffff;
    margin-left: 1em;
}

.settings-page-club-selector > .dropdown-toggle, .settings-page-club-selector > button:hover {
    color: #fff !important;
    border-color: #fff !important;
    width: 90%;
    overflow-x: hidden;
}

.settings-page-navigation-item {
    margin-top: 0.25em;
    padding: 1em;
    color: white;
    cursor: pointer;
}
.settings-page-navigation-item-selected {
    @extend .settings-page-navigation-item;

    background-color: var(--darker-handicaddie-green);
}

.slot-type-container {
    width: calc(100% - 300px);
    padding: 1em;
}

@media (max-width: 767px) {
    .settings {
        display: block;
    }

    .navigation-sidebar {
        width: 100%;
        min-height: auto;
    }

    .slot-type-container {
        width: 100%;
    }
}