.club-selector-dropdown {
  display: flex;
  padding: 15px 10px;
  justify-content: end;

  .border-b-dropdown > button {
    min-width: 0;
    padding: 5px 10px;
    border-radius: 5px;
    color: #4caf50 !important;
    border: 1px solid #4caf50 !important;
  }

  .border-b-dropdown > button:hover {
    color: #ffffff !important;
    background-color: #4caf50 !important;
  }
}

.dashboardHeading {
  font-size: 1.5em;
  font-weight: bold;
  color: black;
}

.welcomeHeading {
  @extend .dashboardHeading;
  text-transform: uppercase;
}

.yourCaddieProgramHeading {
  @extend .dashboardHeading;
  margin: 0;
}

.welcomeSubHeading {
  font-size: 1em;
  color: #707070;
}