.tourCaddieApplicationCard_userInfo {
  padding: 1em;
}

.tourCaddieApplicationCard_userInfo_avatar {
  width: 5em;
  height: 5em;
}

.tourCaddieApplicationCard_userInfo_name {
  font-weight: bold;
  text-transform: uppercase;
  line-height: 3em;
}

.tourCaddieApplicationCard_footer_reviewBtn {
  width: 100%;
  margin-bottom: 0.5em;
}

.tourCaddieApplicationCard_footer_decisionRow {
  display: flex;
  justify-content: space-between;
}

.tourCaddieApplicationCard_footer_btn {
  width: 100%;
  margin-bottom: 0.5em;
}
